import { createContext, useState, useEffect } from 'react'
import LoadingScreen from 'src/components/LoadingScreen'
import { mapObjValues, isEmpty } from 'src/utils'
export const Context = createContext()

export const Provider = (props) => {
  const { children } = props
  const { localStorage, __APP__, __THEME__ } = window
  const [clientId, setClientId] = useState(process.env.CLIENT_ID || 'default')
  const cachedUser = JSON.parse(localStorage.getItem('user') || 'null')
  const [user, setUser] = useState(cachedUser)
  const [assets, setAssets] = useState({})
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState()
  const [units, _setUnits] = useState({})
  const setUnits = (units) => {
    _setUnits(units)
    localStorage.setItem('units', JSON.stringify(units))
  }
  useEffect(() => {
    setAssets(__THEME__)
    setConfig(__APP__)
    setLoading(false)
  }, [])
  useEffect(() => { // prepare units config
    if (config) {
      const { units = {} } = config
      const cachedUnits = JSON.parse(localStorage.getItem('units') || 'null')
      if (cachedUnits && !isEmpty(cachedUnits)) {
        const formatted = mapObjValues(cachedUnits, (cachedUnits) => ({
          ...cachedUnits.default,
          ...cachedUnits
        }))
        setUnits(formatted)
      } else {
        const formatted = mapObjValues(units, (unit) => ({
          ...units.default,
          ...unit

        }))
        setUnits(formatted)
      }
    }
  }, [config])
  const value = {
    user,
    clientId,
    client: assets,
    config,
    units,
    setUnits
  }
  return (
    <Context.Provider value={value}>
      {loading
        ? <LoadingScreen />
        : children}
    </Context.Provider>
  )
}
