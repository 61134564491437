// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path='/resetPassword/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/admin/logs' page={LogPage} name='log' />
      <Route path='/admin/user' page={AdminUserPage} name='adminUser' />
      <Route path='/admin/space/{id:Int}' page={SpacePageAdmin} name='spacePageAdmin' />
      <Route path='/admin/space/' page={SpacePageAdmin} name='spacePageAdmin' />
      <Route path='/admin/algorithm/{id}' page={AlgorithmPage} name='algorithmPage' />
      <Route path='/admin/algorithm/' page={AlgorithmPage} name='algorithmPage' />
      <Route path='/admin/sensor/{id:Int}' page={AdminSensorPage} name='adminSensor' />
      <Route path='/admin/{type}' page={AdminPage} name='adminContent' />
      <Route path='/admin' page={AdminPage} name='admin' />
      <Route path='/space/{id:Int}' page={SpacePage} name='space' />
      <Route path='/app/building/{id:Int}' page={BuildingsPage} name='building' />
      <Route path='/app/building' page={BuildingsPage} name='buildings' />
      <Route path='/app' page={AppPage} name='app' />
      <Route path='/dashboard' page={DashboardPage} name='dashboard' />
      <Route path='/reset/password/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/' page={LoginPage} name='login' />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
