/**
 * Turn array of object into one object
 * based on all of the objeccts properties
 * @param {[Object]} arr
 * @param {(key, acc, cur)} cb
 * @returns {Object}
 */
export const reduceToOne = (arr, cb) => {
  return arr.reduce((acc, cur, i) => ({
    ...Object.keys(cur)
      .reduce((accObj, key, j) => ({
        ...accObj,
        [key]: cb(key, acc, cur, i, j)
      }), {})
  }), {})
}

/**
 * Turn array of object into string
 * based on all of the objeccts properties
 * @param {[Object]} arr
 * @returns {String}
 */
export const reduceToCSV = (arr) => {
  const SEP = ','
  const NL = '\n'
  return arr.reduce((csv, obj, i) => {
    let acc = csv
    if (i === 0) { // print header
      acc += Object.keys(obj)
        .reduce((header, key, i) =>
          (i === 0)
            ? `${key}`
            : `${header}${SEP}${key}`, {}, '') + NL
    }
    acc += Object.keys(obj)
      .reduce((row, key, i) =>
        (i === 0)
          ? `${obj[key]}`
          : `${row}${SEP}${obj[key]}`, '') + NL
    return acc
  }, '')
}

export const groupBy = (arr, key) => {
  return arr
    .reduce((acc, cur) => {
      const grouper = cur[key]
      return {
        ...acc,
        [grouper]: acc[grouper]
          ? [...acc[grouper], cur]
          : [cur]
      }
    }, {})
}

/**
 * Map array of object and map the objects
 * properties one by one
 * @param {[Object]} arr
 * @param {(key, acc, cur)} cb
 * @returns {[Object]}
 */
export const mapEachObjKeys = (arr, cb) => {
  return arr.map((point) => ({
    ...Object.keys(point)
      .reduce((accObj, key) => ({
        ...accObj,
        [key]: cb(key, accObj, point)
      }), {})
  }))
}

/**
 * Map each key of object and return array
 * @param {Object} obj
 * @param {(value, key)} cb
 * @returns {[Any]}
 */
export const mapKeys = (obj, cb) => {
  return Object.keys(obj)
    .map((key, i) => cb(obj[key], key, i))
}

/**
 * Map an objects values
 * not recursive
 * @param {Object} obj
 * @param {(value, key, index)} cb
 * @returns {Object}
 */
export const mapObjValues = (obj, cb) => {
  return Object.keys(obj)
    .reduce((acc, key, i) => ({
      ...acc,
      [key]: cb(obj[key], key, i)
    }), {})
}

/**
 * Filter object of properties, creates new object.
 * @param {Object} obj
 * @param {(value, key, index)} cb
 * @returns {Object}
 */
export const filterObj = (obj, cb) => {
  return Object.keys(obj)
    .reduce((acc, key, i) => ({
      ...acc,
      ...(cb(obj[key], key, i) === true) && { [key]: obj[key] }
    }), {})
}

/**
 * CREDITS: @ Nathan Berton https://stackoverflow.com/questions/39776819/function-to-normalize-any-number-from-0-1
 * @param {*} val
 * @param {*} max
 * @param {*} min
 */
export const normalize = (val, max, min) =>
  ((val - min) / (max - min))

/**
 *
 * @param {*} obj
 * @param {([keyA,keyB], [valueA, valueB])} cb
 */
export const sortObj = (obj, cb) => {
  const sortedKeys = Object.keys(obj)
    .sort((a, b) => {
      const keys = [a, b]
      const values = [obj[a], obj[b]]
      return cb(keys, values)
    })
  return sortedKeys.reduce((acc, cur) => ({
    ...acc,
    [cur]: obj[cur]
  }), {})
}

/**
 * CREDITS: https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object Adam Zerner
 */
export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
